import * as React from "react"
import { Seo } from "../../components/seo"
import Layout from '../../components/layout'
import Sidebar from '../../components/sidebar'
import { StaticImage } from "gatsby-plugin-image"
import "../../css/page-staff.scss"

const BODPage = () => {
  return (
	<Layout>
		<section id="hero">
			<StaticImage src="../../images/hero-images/hero-image-1.jpg" alt="Hero Background" class="heroImage" />
			<div className="heroOverlay"></div>
			<div className="container">
				<h1>Board of Directors</h1>
			</div>
		</section>
		<section id="pageContent">
			<div className="container withSidebar">
				<main>
					<div className="staffGrid">
						<div className="staffMember">
							<div className="staffImageWrapper">
								<StaticImage src="../../images/staff/kay.jpg" alt="Kay Anderson" class="staffImage" />
							</div>
							<h5>Kay Anderson</h5>
							<p>President</p>
						</div>
						<div className="staffMember">
							<div className="staffImageWrapper">
								<StaticImage src="../../images/staff/julie-berndt.jpg" alt="Julie Berndt" class="staffImage" />
							</div>
							<h5>Julie Berndt</h5>
							<p>Vice President / Volunteer Relations</p>
						</div>
						<div className="staffMember">
							<div className="staffImageWrapper">
								<StaticImage src="../../images/staff/jeff-musson.jpg" alt="Jeff Musson" class="staffImage" />
							</div>
							<h5>Jeff Musson</h5>
							<p>Director</p>
						</div>
						<div className="staffMember">
							<div className="staffImageWrapper">
								<StaticImage src="../../images/staff/pam-bishop.jpg" alt="Pam Bishop" class="staffImage" />
							</div>
							<h5>Pam Bishop</h5>
							<p>Secretary</p>
						</div>
						<div className="staffMember">
							<div className="staffImageWrapper">
								<StaticImage src="../../images/staff/andy-cordova.jpg" alt="Any Cordova" class="staffImage" />
							</div>
							<h5>Andy Cordova</h5>
							<p>Treasurer</p>
						</div>
						<div className="staffMember">
							<div className="staffImageWrapper">
								<StaticImage src="../../images/staff/bob-chasteen.jpg" alt="Bob Chasteen" class="staffImage" />
							</div>
							<h5>Bob Chasteen</h5>
							<p>Resource Development</p>
						</div>
						<div className="staffMember">
							<div className="staffImageWrapper">
								<StaticImage src="../../images/staff/Ann.jpeg" alt="Ann Sorensen" class="staffImage" />
							</div>
							<h5>Ann Sorensen</h5>
							<p>Construction Manager</p>
						</div>
						<div className="staffMember">
							<div className="staffImageWrapper">
								<StaticImage src="../../images/staff/elvis-bauman.jpg" alt="Elvis Bauman" class="staffImage" />
							</div>
							<h5>Elvis Bauman</h5>
							<p>ReStore Chair</p>
						</div>
						<div className="staffMember">
							<div className="staffImageWrapper">
								<StaticImage src="../../images/staff/tracey-barnes-2.jpg" alt="Tracey Barnes" class="staffImage" />
							</div>
							<h5>Tracey Barnes</h5>
							<p>Public Relations and Marketing</p>
						</div>
					</div>
				</main>
				<Sidebar />
			</div>
		</section>
	</Layout>
  )
}
export default BODPage

export const Head = () => (
	<Seo
        title="Board of Directors | Habitat For Humanity Northwoods Wisconsin"
    />
)
